
let menu;
let menuBtn;

const MENU_OPENED_CLASS = 'menu-opened';

function setupDomReferences() {
	menu = document.querySelector('[data-main-menu]');
	menuBtn = menu.querySelector('[data-menu-btn]');
}

function handleMenuBtnClick(e) {
	e.preventDefault();
	menu.classList.toggle(MENU_OPENED_CLASS);
	document.body.classList.toggle(MENU_OPENED_CLASS);
}

export default {
	init() {
		setupDomReferences();

		menuBtn.addEventListener('click', handleMenuBtnClick);
	},
};
