
import Isotope from 'isotope-layout';

const defaultFilter = '*';
let filters = null;
let iso = null;
let filterSelect = null;

function onFilterClick(e) {
	e.preventDefault();

	filters.forEach(f => { f.classList.remove('active'); });
	e.currentTarget.classList.add('active');

	const elemFilter = e.currentTarget.getAttribute('data-masonry-filter');
	if (iso) {
		iso.arrange({
			filter: (index, elem) => {
				return (elemFilter === defaultFilter) || (elem.getAttribute('data-masonry-filter-elem').split('|').indexOf(elemFilter) > -1);
			},
		});
	}
}

function onSelectChange(e) {
	if (iso) {
		const newFilter = e.currentTarget.value;
		iso.arrange({
			filter: (index, elem) => {
				return (newFilter === defaultFilter) || (elem.getAttribute('data-masonry-filter-elem').split('|').indexOf(newFilter) > -1);
			},
		});
	}
}

export default {
	init() {
		const listing = document.querySelector('[data-masonry-container]');
		filters = document.querySelectorAll('[data-masonry-filter]');
		filterSelect = document.querySelector('[data-masonry-select]');

		if (!listing) {
			return;
		}

		filterSelect.addEventListener('change', onSelectChange);
		filters.forEach(f => f.addEventListener('click', onFilterClick));

		const urlFilter = new URL(window.location.href).searchParams.get('cat');

		iso = new Isotope(listing, {
			itemSelector: '[data-masonry-item]',
			layoutMode: 'masonry',
			filter: (index, elem) => {
				return urlFilter ? (elem.getAttribute('data-masonry-filter-elem').split('|').indexOf(urlFilter) > -1) : true;
			},
		});
	},
};
