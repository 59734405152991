

import $ from 'jquery';
import slick from 'slick-carousel';

let slider;
let slides;
let controls;
let next;
let prev;
let counter;
let currentIdx;
let total;

function setupDomReferences() {
	slider = $('[data-featured-slider]');
	slides = $('[data-featured-slide]');
	controls = $('[data-featured-slider-controls]');
	next = $('[data-next]', controls);
	prev = $('[data-prev]', controls);
	counter = $('[data-counter]', controls);
	currentIdx = $('[data-current]', counter);
	total = $('[data-total]', total);
}

export default {
	init() {
		setupDomReferences();

		if (slider.length > 0) {
			total.html(slides.length);
			
			slider.slick({
				fade: true,
				arrows: false,
				dots: false,
			});

			slider.on('beforeChange', (event, _slick, currentSlide, nextSlide) => {
				currentIdx.html(nextSlide + 1);
			});

			next.on('click.slider', () => {
				slider.slick('slickNext');
			});

			prev.on('click.slider', () => {
				slider.slick('slickPrev');
			});
		} 
	},
};
