

import $ from 'jquery';
import { isMobile } from './utils/isMobile';

let stickySidebar;
let stickySidebarContainer;
let stickySidebarOriginalPos;
let stickySidebarWidth;

const OFFSET_TOP = 30;

function setupDomReferences() {
	stickySidebar = $('[data-sticky-sidebar]');
	stickySidebarContainer = $('[data-sticky-sidebar-container]');
}

function handleScroll() {
	if (window.scrollY + OFFSET_TOP >= stickySidebarOriginalPos.top) {
		stickySidebar.addClass('sticky');
		
		if (window.scrollY - OFFSET_TOP + stickySidebar.height() >= stickySidebarContainer.height()) {
			stickySidebar.css({
				position: 'absolute',
				top: 'auto',
				bottom: 0,
				left: 15,
				height: 'auto',
				width: stickySidebarWidth,
			});
		} else {
			stickySidebar.css({
				position: 'fixed',
				top: OFFSET_TOP,
				left: stickySidebarOriginalPos.left,
				bottom: 'auto',
				height: 'auto',
				width: stickySidebarWidth,
			});
		}
	} else if (window.scrollY + OFFSET_TOP < stickySidebarOriginalPos.top && stickySidebar.hasClass('sticky')) {
		stickySidebar.removeClass('sticky');
		stickySidebar.css({
			position: 'relative',
			top: 'auto',
			left: 'auto',
			width: 'auto',
			height: 'auto',
		});
	}
}

function handleResize() {
	if (isMobile(1189)) {
		if (stickySidebar.hasClass('sticky')) {
			stickySidebar.removeClass('sticky');
			stickySidebar.css({
				position: 'relative',
				top: 'auto',
				left: 'auto',
				width: 'auto',
				height: 'auto',
			});
		}

		window.removeEventListener('scroll', handleScroll);
	}
}

export default {
	init() {
		setupDomReferences();
		stickySidebarOriginalPos = stickySidebar.offset();
		stickySidebarWidth = stickySidebar.outerWidth();

		if (stickySidebar.length > 0) {
			window.addEventListener('scroll', handleScroll);
			window.addEventListener('resize', handleResize);
			handleResize();
		}

	},
};
