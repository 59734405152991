
import $ from 'jquery';
import { TweenMax } from 'gsap';

let contactForm;
let inputFields;

function setUpDomReferences() {
	contactForm = document.querySelector('[data-contact-form]');
	inputFields = document.querySelectorAll('input[type=text], input[type=tel], input[type=email], input[type=number], input[type=password], textarea');
}

function handleInputChange(e) {
	const el = e.currentTarget;

	if (el.value === '') {
		el.classList.add('empty');
		el.parentNode.querySelector('label').classList.remove('visible');
	} else {
		el.classList.remove('empty');
		el.parentNode.querySelector('label').classList.add('visible');
	}
}

function handleContactFormSubmit(e) {
	e.preventDefault();
	const $contactForm = $(contactForm);
	
	if (contactForm.checkValidity()) {
		TweenMax.to($contactForm.find('.form-content'), 0.6, { 
			opacity: 0,
			onComplete() {
				
				$.ajax({
					url: $contactForm.attr('action'),
					data: $contactForm.serializeArray(),
					dataType: 'JSON',
					method: 'POST',
					success(res) {
						const messageDiv = $contactForm.find('[data-message]');
						
						if (res.success) {
							$contactForm.find('.form-content').slideUp();
							messageDiv.addClass('success').slideDown();
						} else {
							messageDiv.addClass('failure').slideDown();
							TweenMax.to($contactForm.find('.form-content'), 0.6, { opacity: 0 });
						}
					},
				});
			},
		});
	}
}

export default {
	init() {
		setUpDomReferences();

		inputFields.forEach((input) => {
			['keypress', 'blur', 'change', 'input'].forEach(event => {
				input.addEventListener(event, handleInputChange);
			});
		});

		if (contactForm) {
			contactForm.addEventListener('submit', handleContactFormSubmit);
		}
	},
};
